<template>  
    <mbs-page-spinner v-if="false" />  
    <v-card outlined id="print_template" v-else>
        <v-card-text class="mb-9 px-6 py-10">
            <v-layout justify-center align-center class="mt-9 mb-9">
                <div class="text-center py-5">
                    <div>{{selectedCompany?selectedCompany.company_name:'...'}}</div>
                    <div class="font-weight-bold">Profit and Loss</div>
                    <div>{{MBS.date.moment().format('dddd, Do, MMMM YYYY')}}</div> 
                </div>
            </v-layout>
            <hr> 
            <br> 
            <v-list-item>
                <v-list-item-content>Revenue</v-list-item-content>
                <v-list-item-action>TOTAL</v-list-item-action>
            </v-list-item>
            <hr> 
            <v-list>
                <v-list-item>
                    <v-list-item-content>Sales</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(TotalSalesOrderItems)}}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>Cost of Sales</v-list-item-content>
                    <v-list-item-action>
                        <v-card min-width="80" tile flat class="text-right">
                            {{MBS.actions.money(TotalCostSalesOrderItems)}}
                            <hr>
                        </v-card>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item class="font-weight-bold">
                    <v-list-item-content>Gross Profit</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(GrossProfit)}}</v-list-item-action>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list-group
                v-model="expend_expenses"
                :no-action ="true" 
                :sub-group ="false" 
                append-icon="" 
                >
                <template v-slot:activator class="ma-0 pa-0"> 
                    <v-list-item-action class="ma-0 pa-0">
                        <v-icon class="pr-2" v-if="expend_expenses">expand_more</v-icon>
                        <v-icon class="pr-2" v-else>chevron_right</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Expenses</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="!expend_expenses">
                        {{MBS.actions.money(TotalExpenses)}}
                    </v-list-item-action>
                </template>

                <v-list-item class="pl-13"
                    v-for="(expense_account, i) in CalculatedExpensesAccounts"
                    :key="i"
                    link >
                    <v-list-item-title >{{expense_account.name}}</v-list-item-title> 
                    <v-list-item-action> {{expense_account.total_amount_}} </v-list-item-action>
                </v-list-item>
                <v-divider class="ml-13"></v-divider>
                <v-list-item class="font-weight-bold pl-13">
                    <v-list-item-content>Total Expenses</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(TotalExpenses)}}</v-list-item-action>
                </v-list-item>
            </v-list-group>
            <br>
            <hr>
            <v-list-item class="font-weight-bold">
                <v-list-item-content>NET PROFIT</v-list-item-content>
                <v-list-item-action>{{MBS.actions.money(GrossProfit-TotalExpenses)}}</v-list-item-action>
            </v-list-item> 
            <br> 
        </v-card-text> 
    </v-card>
</template>

<script>
    import DATA from "../../../plugins/DATA"
    let NAME = 'PROFIT-AND-LOSS-TEMP'
    import {mapState} from "vuex"
    export default {
        props:['data','sale_orders','expenses'], 
        name:NAME,
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                expend_expenses:true,
            } 
        }, 
        mounted(){ 
        },
        computed:{ 
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },  

            Joined_SaleOrders(){
                let sales = this.sale_orders
                if(!sales){return null}
                let join = this.MBS.actions.JOIN({
                    items:sales,
                    name:DATA.ITEMS.SALE_ORDERS.values
                })
                return join
            } ,
            CalculatedExpensesAccounts(){ 
                let expenses = this.expenses
                if(!expenses){return null}
                let accounts = []
                for (const expense of expenses) {
                    let amount = this.toNumber(expense.amount)
                    // let expense_account = expense.expense_account
                    let expense_account = expense.expense_account?expense.expense_account:{name:"Unknown",code:0}
                    if (expense_account) {
                        let index =  accounts.findIndex(account=>{
                            return account.code == expense_account.code
                        })
                        if (index==-1) {
                            accounts.push({
                                ...expense_account,
                                total_amount:amount,
                                total_amount_:this.MBS.actions.money(amount),
                            })
                        } else {
                            accounts[index].total_amount = accounts[index].total_amount+amount
                            accounts[index].total_amount_ = this.MBS.actions.money(accounts[index].total_amount)
                        }
                    } 
                }
                return accounts
            }, 

            TotalSalesOrderItems(){ 
                // let sales = this.Joined_SaleOrders 
                let sales = this.sale_orders
                let total = 0
                if(!sales){return total}
                sales.forEach(element => {
                    total = total+this.toNumber(element.total_sold_price) 
                });
                return total
            }, 
            TotalCostSalesOrderItems(){ 
                // let sales = this.Joined_SaleOrders 
                let sales = this.sale_orders 
                let total = 0
                if(!sales){return total}
                sales.forEach(element => {   
                    total = total+this.toNumber(element.total_cost_price) 
                });
                return total
            }, 
            TotalExpenses(){ 
                let expenses = this.CalculatedExpensesAccounts
                let total = 0
                if(!expenses){return total}
                expenses.forEach(element => {
                    total = total+this.toNumber(element.total_amount)
                });
                return total
            },  
            GrossProfit(){ 
                let totalSales = this.TotalSalesOrderItems
                let totalCost = this.TotalCostSalesOrderItems
                return totalSales - totalCost
            },  
             
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
